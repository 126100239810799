<script>
import PopperModal from "@/components/elements/PopperModal.vue";
import MultiSelectWithSearch from "@/components/MultiSelectWithSearch.vue";
import SearchDropdown from "@/components/modules/ia/SearchDropdown.vue";
import {AlertService} from "@/services/AlertService";
import {DataService} from "@/services/DataService";
import DateMixin from "@/mixins/DateMixin";
import AuditorMixin from "@/mixins/AuditorMixin";
import AuditRecordMixin from "@/mixins/AuditRecordMixin";
import ObjectMixin from "@/mixins/ObjectMixin";
import {mapActions, mapGetters} from "vuex";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import SelectWithSearch from "@/components/common/SelectWithSearch.vue";


export default {
  name: "ScheduleAudit",
  data() {
    return {
      audit_type_1 : [],
      newVesselAudit : {
        sms: null,
        isps: null,
        cyber : null
      },
      activeAuditType : 'sms',
      keysToAssign : [
        'appointment_date',
        'audit_country_id',
        'audit_date',
        'audit_port_id',
        'audit_type_2',
        'audit_type_3',
        'extension',
        'extension_date',
        'lead_ids',
        'members_ids',
      ]
    }
  },
  components: {DatePicker, SearchDropdown, MultiSelectWithSearch, PopperModal, SelectWithSearch},
  mixins:[DateMixin, AuditorMixin, ObjectMixin, AuditRecordMixin],
  methods: {
    setAuditTypeTwo(type) {
      this.newVesselAudit[this.activeAuditType].audit_type_2 = this.newVesselAudit[this.activeAuditType].audit_type_2.filter(item => item === type);
      this.newVesselAudit[this.activeAuditType] = DataService.pushOrRemoveInData(this.newVesselAudit[this.activeAuditType], 'audit_type_2', type);
    },

    setAuditTypeThree(type) {
      this.newVesselAudit[this.activeAuditType].audit_type_3 = this.newVesselAudit[this.activeAuditType].audit_type_3.filter(item => item === type);
      this.newVesselAudit[this.activeAuditType] = DataService.pushOrRemoveInData(this.newVesselAudit[this.activeAuditType], 'audit_type_3', type);
    },
    setAuditTypeOne(indexType, type) {
      if (this.audit_main_types[0].enabled === false && this.audit_main_types[1].enabled === false && this.audit_main_types[2].enabled === false) {
        this.activeAuditType = type;
      }
      this.audit_main_types[indexType].enabled = !this.audit_main_types[indexType].enabled;
      if (this.audit_main_types[indexType].type === this.activeAuditType) {
        if (indexType === 0 && this.audit_main_types[indexType].enabled === false) {
          if (this.audit_main_types[1].enabled === true) {
            this.activeAuditType = 'isps';
          } else if (this.audit_main_types[2].enabled === true) {
            this.activeAuditType = 'cyber';
          }
        } else if (indexType === 1 && this.audit_main_types[indexType].enabled === false) {
          if (this.audit_main_types[0].enabled === true) {
            this.activeAuditType = 'sms';
          } else if (this.audit_main_types[2].enabled === true) {
            this.activeAuditType = 'cyber';
          }
        } else if (indexType === 2 && this.audit_main_types[indexType].enabled === false) {
          if (this.audit_main_types[0].enabled === true) {
            this.activeAuditType = 'sms';
          } else if (this.audit_main_types[1].enabled === true) {
            this.activeAuditType = 'isps';
          }
        }
      }
      this.newVesselAudit[type] = this.addTypeInitialValues(type);
    },
    setActiveAuditType(indexType) {
      if (this.audit_main_types[indexType].enabled === true) {
        if (indexType === 0) {
          this.activeAuditType = 'sms';
        } else if (indexType === 1) {
          this.activeAuditType = 'isps';
        } else {
          this.activeAuditType = 'cyber';
        }
      }
    },
    async createNewAuditRecord() {
      let newRecord = this.getDataBasedOnActiveTypes(this.newVesselAudit);
      newRecord.vessel_id = parseInt(this.$route.params.vessel_id);

      let createRequest = await this.createAuditRecord(newRecord);
      if (createRequest.result === false) {
        this.activeAuditType = this.getTypeThatHasAnError(Object.keys(createRequest.errors));
        return AlertService.errorAlert(createRequest.message, 'SAVING NEW VESSEL AUDIT RECORD');
      }

      this.initializeData();
      this.resetAuditMainTypes();

      $('#ScheduleNewVesselAuditModal').modal('hide');

      let firstLandingType = this.getFirstLandingType(newRecord);
      successAlert.fire({
        title: `ADDING OF NEW VESSEL AUDIT RECORD`,
        showConfirmButton: true,
        allowOutsideClick:false,
        allowEnterKey:false,
        allowEscapeKey:false,
        timer: 1500,
        scrollbarPadding: false
      }).then(async (result)=>{
        if(result.isConfirmed){
          await this.$router.push({ name: 'internalAuditReport', params: {
              id: createRequest.data.id,
              vessel_id: createRequest.data.vessel_id,
              type: firstLandingType
          } }).catch(() => {
          })
        }
      })

      setTimeout(() => {
        if (this.$route.name !== 'internalAuditReport') {
          this.$router.push({name: 'internalAuditReport', params: {
              id: createRequest.data.id,
              vessel_id: createRequest.data.vessel_id,
              type: firstLandingType
          }})
        }
      }, 2000);
    },
    async cancelAdd() {
      if (this.noSelectedAuditTypeOne === true) {
        return $('#ScheduleNewVesselAuditModal').modal('hide');
      }
      const response = await AlertService.cancelAlert();
      if (response) {
        this.initializeData();
        this.resetAuditMainTypes();
        $('#ScheduleNewVesselAuditModal').modal('hide');
      }
    },
    setLeadMembers(leadMemberIds) {
      this.newVesselAudit[this.activeAuditType].lead_ids = leadMemberIds.selected.length > 0 ? leadMemberIds.selected : [];
    },
    setNonLeadMembers(nonLeadMemberIds) {
      this.newVesselAudit[this.activeAuditType].members_ids = nonLeadMemberIds.selected.length > 0 ? nonLeadMemberIds.selected : [];
    },
    syncObjectToOtherObject(auditTypeToSync) {
      let newObject = DataService.assignObjectKeysToOtherObject(this.newVesselAudit[auditTypeToSync], this.keysToAssign);
      this.newVesselAudit[this.activeAuditType] = DataService.setValuesFromNewObject(this.newVesselAudit[this.activeAuditType], newObject);
      },
    setSameAsValue(selectionIndex = null) {
      if (this.sameAsEventHandler.selection !== undefined) {
        if (this.sameAsEventHandler.selection[selectionIndex].same_as === this.newVesselAudit[this.activeAuditType].same_in.value) {
          this.newVesselAudit[this.activeAuditType].same_in.enabled = false;
          this.newVesselAudit[this.activeAuditType].same_in.value = null;
        } else {
          this.newVesselAudit[this.activeAuditType].same_in.enabled = true;
          let auditType1 = this.newVesselAudit[this.activeAuditType].same_in.enabled === true ? this.sameAsEventHandler.selection[selectionIndex].same_as : null;
          this.newVesselAudit[this.activeAuditType].same_in.value = auditType1;
          if (auditType1 !== null) {
            this.syncObjectToOtherObject(auditType1);
          }
        }
      } else {
        this.newVesselAudit[this.activeAuditType].same_in.enabled = !this.newVesselAudit[this.activeAuditType].same_in.enabled;
        let auditType1 = this.newVesselAudit[this.activeAuditType].same_in.enabled === true ? this.sameAsEventHandler.same_as : null;
        this.newVesselAudit[this.activeAuditType].same_in.value = auditType1;
        if (auditType1 !== null) {
          this.syncObjectToOtherObject(auditType1);
        }
      }
    },
    initializeData() {
      this.newVesselAudit.sms = this.addTypeInitialValues('sms');
      this.newVesselAudit.isps = this.addTypeInitialValues('isps');
      this.newVesselAudit.cyber = this.addTypeInitialValues('cyber');
    }
  },
  computed: {
    ports() {
      return this.portKeys.filter(port => port.country_id === this.newVesselAudit[this.activeAuditType]?.audit_country_id)
    },
    noSelectedAuditTypeOne() {
      let disabled = true;
      for (let counter = 0; counter < 3; counter++) {
        if (this.audit_main_types[counter].enabled === true) {
          disabled = false;
        }
      }
      return disabled;
    },
    disabledElements() {
      return this.noSelectedAuditTypeOne || this.newVesselAudit[this.activeAuditType].same_in.enabled === true
    },
    sameAsEventHandler() {
      if (this.audit_main_types[0].enabled === false && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === true && this.activeAuditType === 'cyber') {
        return {
          type      : 'cyber',
          text      : 'SAME AS ISPS',
          same_as   : 'isps'
        }
      }

      if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === false && this.audit_main_types[2].enabled === true && this.activeAuditType === 'cyber') {
        return {
          type      : 'cyber',
          text      : 'SAME AS SMS',
          same_as   : 'sms'
        }
      }

      if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === false && this.activeAuditType === 'isps') {
        return {
          type      : 'isps',
          text      : 'SAME AS SMS',
          same_as   : 'sms'
        }
      }

      if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === true && this.activeAuditType === 'isps') {
        return {
          type      : 'isps',
          text      : 'SAME AS SMS',
          same_as   : 'sms'
        }
      }

      if (this.audit_main_types[0].enabled === true && this.audit_main_types[1].enabled === true && this.audit_main_types[2].enabled === true && this.activeAuditType === 'cyber') {
        if (this.newVesselAudit.isps.same_in.enabled === true) {
          return {
            type      : 'cyber',
            text      : 'SAME AS SMS AND ISPS',
            same_as   : 'isps'
          }
        }
        return {
          type      : 'cyber',
          text      : null,
          selection : [
            {
              text : 'SAME AS SMS',
              same_as : 'sms',
            },
            {
              text : 'SAME AS ISPS',
              same_as : 'isps',
            }
          ]
        }
      }

      return {
        audit_type : null,
        type       : null,
        text      : null
      }
    },
    getMultiCustomKey() {
      return this.getIndexOfType(this.activeAuditType)
    },
    disabledSmsCheckBox() {
      const ispsSameAsSms = this.newVesselAudit.isps.same_in.enabled === true && this.newVesselAudit.isps.same_in.value === 'sms';
      const cyberSameAsSms = this.newVesselAudit.cyber.same_in.enabled === true && this.newVesselAudit.cyber.same_in.value === 'sms';
      return ispsSameAsSms || cyberSameAsSms;
    },
    disabledIspsCheckBox() {
      const cyberSameAsIsps = this.newVesselAudit.cyber.same_in.enabled === true && this.newVesselAudit.cyber.same_in.value === 'isps';
      return cyberSameAsIsps;
    }
  },
  async created() {
    this.initializeData();
  },
  watch : {
    'newVesselAudit.sms' : {
      handler() {
        let newObject = DataService.assignObjectKeysToOtherObject(this.newVesselAudit.sms, this.keysToAssign);
        if (this.newVesselAudit.isps.same_in.enabled === true && this.newVesselAudit.isps.same_in.value === 'sms') {
          this.newVesselAudit.isps = DataService.setValuesFromNewObject(this.newVesselAudit.isps, newObject);
        }

        if (this.newVesselAudit.cyber.same_in.enabled === true && this.newVesselAudit.cyber.same_in.value === 'sms') {
          this.newVesselAudit.cyber = DataService.setValuesFromNewObject(this.newVesselAudit.cyber, newObject);
        }
      },
      deep: true
    },
    'newVesselAudit.isps' : {
      handler() {
        let newObject = DataService.assignObjectKeysToOtherObject(this.newVesselAudit.isps, this.keysToAssign);
        if (this.newVesselAudit.cyber.same_in.enabled === true && this.newVesselAudit.cyber.same_in.value === 'isps') {
          this.newVesselAudit.cyber = DataService.setValuesFromNewObject(this.newVesselAudit.cyber, newObject);
        }
      },
      deep: true
    }
  }
}
</script>


<template>

  <div class="modal fade" data-backdrop="static" data-keyboard="false" id="ScheduleNewVesselAuditModal" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="createNewAuditRecord">
        <div class="modal-header">
          <div class="modal-title w-100 text-center">
            <h5 class="font-weight-bold">Vessel Audit Appointment and Plan</h5>
          </div>
        </div>
        <div class="modal-body">
          <!--   FOR AUDIT TYPE 1 -->
          <div class="form-group row no-gutters mb-1">
            <label for="audit_type_1" class="font-weight-bold col-sm-3 col-form-label">Audit Type 1</label>
            <div class="form-check form-check-inline">
              <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_sms" @click="setAuditTypeOne(0, 'sms')" :checked="audit_main_types[0].enabled" :disabled="disabledSmsCheckBox">
              <label for="audit_type1_sms" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">SMS</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_isps" @click="setAuditTypeOne(1, 'isps')" :checked="audit_main_types[1].enabled" :disabled="disabledIspsCheckBox">
              <label for="audit_type1_isps" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">ISPS</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type1_cyber" @click="setAuditTypeOne(2, 'cyber')" :checked="audit_main_types[2].enabled">
              <label for="audit_type1_cyber" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Cyber</label>
            </div>
          </div>

<!--          NAVIGATION FOR SELECTED AUDIT TYPE-->
          <nav class="nav nav-pills nav-justified mb-2">
            <a :class="['gray nav-item nav-link', {'active_custom': activeAuditType === 'sms'}, {'disabled-type' : audit_main_types[0].enabled === false}]" href="#" @click="setActiveAuditType(0)">SMS</a>
            <a :class="['gray nav-item nav-link', {'active_custom': activeAuditType === 'isps'}, {'disabled-type' : audit_main_types[1].enabled === false}]" href="#" @click="setActiveAuditType(1)">ISPS</a>
            <a :class="['gray nav-item nav-link', {'active_custom': activeAuditType === 'cyber'}, {'disabled-type' : audit_main_types[2].enabled === false}]" href="#" @click="setActiveAuditType(2)">Cyber</a>
          </nav>

<!--          FOR SAME AS VALUES -->
          <div class="form-group row no-gutters mb-1" v-if="sameAsEventHandler.type === activeAuditType">
            <label for="audit_type_1" class="font-weight-bold col-sm-3 col-form-label"></label>
            <template v-if="sameAsEventHandler.selection !== undefined">
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="same_as_sms" name = "same_as_sms" :checked="newVesselAudit[activeAuditType].same_in.enabled && newVesselAudit[activeAuditType].same_in.value === 'sms'" @click="setSameAsValue(0)">
                <label for="same_as_sms" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">{{ sameAsEventHandler.selection[0].text }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="same_as_isps" name = "same_as_isps" :checked="newVesselAudit[activeAuditType].same_in.enabled && newVesselAudit[activeAuditType].same_in.value === 'isps'" @click="setSameAsValue(1)">
                <label for="same_as_isps" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">{{ sameAsEventHandler.selection[1].text }}</label>
              </div>
            </template>
            <div class="form-check form-check-inline" v-else>
              <input class="form-check-input checkbox-input" type="checkbox" value="" id="same_as" name = "same_as" :checked="newVesselAudit[activeAuditType].same_in.enabled" @click="setSameAsValue">
              <label for="same_as" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">{{ sameAsEventHandler.text }}</label>
            </div>
          </div>

            <!--   FOR AUDIT TYPE 2 -->
            <div class="form-group row no-gutters mb-1">
              <label for="audit_type_1" class="font-weight-bold col-sm-3 col-form-label">Audit Type 2</label>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_annual" @click="setAuditTypeTwo(1)" :disabled="disabledElements" :checked="newVesselAudit[activeAuditType].audit_type_2.includes(1)">
                <label for="audit_type2_annual" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Annual</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_occasional" @click="setAuditTypeTwo(2)" :disabled="disabledElements" :checked="newVesselAudit[activeAuditType].audit_type_2.includes(2)">
                <label for="audit_type2_occasional" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Occasional</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type2_navigational" @click="setAuditTypeTwo(3)" :disabled="disabledElements" :checked="newVesselAudit[activeAuditType].audit_type_2.includes(3)">
                <label for="audit_type2_navigational" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Navigational Audit</label>
              </div>
            </div>

            <!--   FOR AUDIT TYPE 3 -->
            <div class="form-group row no-gutters mb-1">
              <label for="audit_type_1" class="font-weight-bold col-sm-3 col-form-label">Audit Type 3</label>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_onsite" @click="setAuditTypeThree(1)" :disabled="disabledElements" :checked="newVesselAudit[activeAuditType].audit_type_3.includes(1)">
                <label for="audit_type3_onsite" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Onsite</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input checkbox-input" type="checkbox" value="" id="audit_type3_remote" @click="setAuditTypeThree(2)" :disabled="disabledElements" :checked="newVesselAudit[activeAuditType].audit_type_3.includes(2)">
                <label for="audit_type3_remote" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer">Remote</label>
              </div>
            </div>

            <!--   Date of Audit -->
            <div class="form-group row no-gutters mb-1">
              <label for="date_of_audit" class="font-weight-bold col-sm-3 col-form-label">Date of Audit</label>
              <div class="col-sm-9">
                <date-picker id = "date-picker" :lang = 'en' type="date" valueType="format" range style="width: 100%" placeholder="Select Date of Audit" :editable="false" v-model="newVesselAudit[activeAuditType].audit_date" :disabled="disabledElements"></date-picker>
              </div>
            </div>

            <!--   Appointment Date  -->
            <div class="form-group row no-gutters mb-1">
              <label for="appointment_date" class="font-weight-bold col-sm-3 col-form-label">Appointment Date</label>
              <div class="col-sm-9">
                <date-picker id = "date-picker" :lang = 'en' type="date" valueType="format" style="width: 100%" placeholder="Select Date of Appointment" :editable="false" v-model="newVesselAudit[activeAuditType].appointment_date" :disabled="disabledElements"></date-picker>
              </div>
            </div>

          <!--  Select Component Country  -->
          <div class="form-group row no-gutters mb-1">
            <label for="country" class="font-weight-bold col-sm-3 col-form-label">Country</label>
            <div class="col-sm-9">
              <select-with-search
                :key="activeAuditType + '_select-search-country_' + audit_main_types[getMultiCustomKey].enabled + '_' + newVesselAudit[activeAuditType].audit_country_id"
                v-model="newVesselAudit[activeAuditType].audit_country_id"
                @change="newVesselAudit[activeAuditType].audit_port_id = null"
                :current-selected-item-id="newVesselAudit[activeAuditType].audit_country_id"
                :search-placeholder="'Search Country...'"
                :disabled="disabledElements"
                :key-item-value="'id'"
                :key-display-text="'name'"
                :items="[{
                  id : null,
                  name : '—— ALL COUNTRIES ——'
                }].concat(countryKeys)"
                :element-class="'form-control form-control-sm text-uppercase'"
              />
            </div>
          </div>

            <!--   Port  -->
            <div class="form-group row no-gutters mb-1">
              <label for="country" class="font-weight-bold col-sm-3 col-form-label">Port</label>
              <div class="col-sm-9">
                <select-with-search
                  :key="activeAuditType + '_select-search-port_' + audit_main_types[getMultiCustomKey].enabled + '_' + newVesselAudit[activeAuditType].audit_port_id"
                  v-model="newVesselAudit[activeAuditType].audit_port_id"
                  :current-selected-item-id="newVesselAudit[activeAuditType].audit_port_id"
                  :search-placeholder="'Search Port...'"
                  :disabled="!ports.length || disabledElements"
                  :key-item-value="'id'"
                  :key-display-text="'name'"
                  :items="[{
                  id : null,
                  name : '—— ALL PORTS ——'
                }].concat(ports)"
                  :element-class="'form-control form-control-sm text-uppercase'"
                />
              </div>
            </div>

            <!--   Lead Auditor  -->
            <div class="form-group row no-gutters mb-1">
              <label for="lead" class="font-weight-bold col-sm-3 col-form-label">Lead</label>
              <div class="col-sm-9">
                <multi-select-with-search
                  :key="activeAuditType + '_lead_' + audit_main_types[getMultiCustomKey].enabled"
                  id="lead-data"
                  :items="leadAndNonLeadAuditors" :entity="'Lead'"
                  @doneSelected="setLeadMembers"
                  :selected="newVesselAudit[activeAuditType].lead_ids"
                  :disabled="disabledElements"
                >
                </multi-select-with-search>
              </div>
            </div>

            <!--   Auditors  -->
            <div class="form-group row no-gutters mb-1">
              <label for="lead" class="font-weight-bold col-sm-3 col-form-label">Member</label>
              <div class="col-sm-9">
                <multi-select-with-search
                  :key="activeAuditType + '_member_' + audit_main_types[getMultiCustomKey].enabled"
                  id="members-data"
                  :items="leadAndNonLeadAuditors" :entity="'Members'"
                  @doneSelected="setNonLeadMembers"
                  :selected="newVesselAudit[activeAuditType].members_ids"
                  :disabled="disabledElements"
                >
                </multi-select-with-search>
              </div>
            </div>

            <!--   Extension  -->
            <div class="form-group row no-gutters mb-1">
              <label for="extension" class="font-weight-bold col-sm-3 col-form-label">Extension</label>
              <div class="col-sm-9">
                <select class="form-control form-control-sm text-uppercase" id="internal" v-model="newVesselAudit[activeAuditType].extension" :disabled="disabledElements" @change="newVesselAudit[activeAuditType].extension_date = null">
                  <option :value="1">Yes</option>
                  <option :value="0">No</option>
                </select>
              </div>
            </div>

            <!--   Extension Date  -->
            <div class="form-group row no-gutters mb-1" v-if="newVesselAudit[activeAuditType].extension === 1">
              <label for="extension_date" class="font-weight-bold col-sm-3 col-form-label">Extension Date</label>
              <div class="col-sm-9">
                <input type="date" :min="minimumDate"  class="form-control form-control-sm" v-model="newVesselAudit[activeAuditType].extension_date" :disabled="disabledElements">
              </div>
            </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" ref="NewUser_close" @click.prevent="cancelAdd">Close</button>
          <button type="submit" class="btn btn-sm btn-primary" :disabled="noSelectedAuditTypeOne" :class="noSelectedAuditTypeOne ? 'e-btn-blue-disabled' : ''">Create New Record</button>
        </div>
      </form>
    </div>
  </div>




</template>

<style scoped>
.active_custom {
  background-color: #d3313c;
  color: #fff;
}

</style>
