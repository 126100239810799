let filterExcelUniqueId = 0;

export default  {
  data() {
    return {
      filterExcelUniqueId: ++filterExcelUniqueId,
      searchedValue : '',
      showFilterMenu : false,
      dataLoader : false,
      data : [],
      previousData : [],
      filterIconColor : '',
      doneToRequest : false,
      showSelectAll : true,
      direction : '',
      addCurrentSelectionToFilter : false,
    };
  },
};
