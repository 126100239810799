<template>
  <div class="absolute-covered-parent-relative">
    <div class="relative-covered-parent-absolute">
      <div class="arrow-sort-container"
           @mouseover="filterIconColor = 'e-text-white'"
           @mouseout="filterIconColor = ''"
           :class="[showFilterMenu === true ? 'active-sort-container' : '']"
      >
        <div class="relative-arrow-sort-container"
        >

          <!--          IF HAS NO DIRECTION AND SELECT ALL IS CHECKED-->
          <template v-if="direction === '' && whereIn.length !== data.length">
            <div style="position: absolute; top: 0; left: 0; width: 50%; height: 100%">
              <font-awesome-icon
                icon="sort-down"
                @mouseover="filterIconColor = 'e-text-white'"
                @mouseout="filterIconColor = ''"
                class="center-inside-container sort-down-icon-with-direction"
                :class="[filterIconColor, showFilterMenu === true ? 'e-text-white' : '']"
              >
              </font-awesome-icon>
            </div>
            <div style="position: absolute; top: 0; left: 50%; width: 50%; height: 100%">
              <font-awesome-icon
                icon='filter'
                @mouseover="filterIconColor = 'e-text-white'"
                @mouseout="filterIconColor = ''"
                class="filter-icon mr-1"
                :class="[filterIconColor, showFilterMenu === true ? 'e-text-white' : '']"
              />
            </div>
          </template>

<!--          IF HAS DIRECTION AND SELECT ALL IS CHECKED-->
          <template v-else-if="direction !== '' && whereIn.length !== data.length">
            <div style="position: absolute; top: 0; left: 0; width: 50%; height: 100%">
              <font-awesome-icon
                :icon="direction === 'asc' ? 'arrow-up' : 'arrow-down'"
                @mouseover="filterIconColor = 'e-text-white'"
                @mouseout="filterIconColor = ''"
                class="sort-arrow-icon-small mr-1"
                :class="[filterIconColor, showFilterMenu === true ? 'e-text-white' : '']"
              />
            </div>
            <div style="position: absolute; top: 0; left: 50%; width: 50%; height: 100%">
              <font-awesome-icon
                icon='filter'
                @mouseover="filterIconColor = 'e-text-white'"
                @mouseout="filterIconColor = ''"
                class="filter-icon mr-1"
                :class="[filterIconColor, showFilterMenu === true ? 'e-text-white' : '']"
              />
            </div>
          </template>
          <!--          IF HAS DIRECTION AND SELECT ALL IS NOT CHECKED-->
          <template v-else-if="direction !== '' && whereIn.length === data.length">
            <div style="position: absolute; top: 0; left: 0; width: 50%; height: 100%">
              <font-awesome-icon
                icon="sort-down"
                @mouseover="filterIconColor = 'e-text-white'"
                @mouseout="filterIconColor = ''"
                class="center-inside-container sort-down-icon-with-direction"
                :class="[filterIconColor, showFilterMenu === true ? 'e-text-white' : '']"
              >
              </font-awesome-icon>
            </div>
            <div style="position: absolute; top: 0; left: 50%; width: 50%; height: 100%">
              <font-awesome-icon
                :icon="direction === 'asc' ? 'arrow-up' : 'arrow-down'"
                @mouseover="filterIconColor = 'e-text-white'"
                @mouseout="filterIconColor = ''"
                class="sort-arrow-icon mr-1"
                :class="[filterIconColor, showFilterMenu === true ? 'e-text-white' : '']"
              />
            </div>
          </template>
          <!--          IF HAS NO DIRECTION AND SELECT ALL IS CHECKED-->
          <template v-else>
            <font-awesome-icon
              icon="sort-down"
              @mouseover="filterIconColor = 'e-text-white'"
              @mouseout="filterIconColor = ''"
              class="center-inside-container sort-down-icon"
              :class="[filterIconColor, showFilterMenu === true ? 'e-text-white' : '']"
            >
            </font-awesome-icon>
          </template>
          <div class="absolute-covered-parent-relative"
               id = "menuShowerOrHider"
               @click="showMenu"
          >
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <div class="filter-option-container" v-if="showFilterMenu === true" id="filter-option-menu" :style="{marginRight : filterOptionToRight === true ? '-232px' : 0}">
        <table class="w-100">
          <colgroup>
            <col style="width: 5%">
            <col style="width: 1%">
          </colgroup>
          <tr
            :class="[direction === 'asc' ? 'selected-row' : '']"
            class="filter-option-list"
            @mouseover="highlightData(0, true)"
            @mouseout="highlightData(0, false)"
            @click="setSorting('asc')"
          >
            <td class="list_filter_0 filter-list-data">A</td>
            <td class="list_filter_0 filter-list-data text-left" rowspan="2">
              <font-awesome-icon icon="long-arrow-alt-down" style="font-size: 39px; transform: scaleX(-0.2); margin-left: -8.5px; z-index: 1000"/>
            </td>
            <td class="list_filter_0 filter-list-data text-left" rowspan="2">Sort A to Z</td>
          </tr>
          <tr
            :class="[direction === 'asc' ? 'selected-row' : '']"
            class="filter-option-list"
            @click="setSorting('asc')"
          >
            <td class="list_filter_0 filter-list-data col-1" style="color: #1e8bcd">Z</td>
          </tr>

          <tr
            :class="[direction === 'desc' ? 'selected-row' : '']"
            class="filter-option-list"
            @mouseover="highlightData(1, true)"
            @mouseout="highlightData(1, false)"
            @click="setSorting('desc')"
          >
            <td class="list_filter_1 filter-list-data">Z</td>
            <td class="list_filter_1 filter-list-data text-left" rowspan="2">
              <font-awesome-icon icon="long-arrow-alt-down" style="font-size: 39px; transform: scaleX(-0.2); margin-left: -8.5px; z-index: 1000"/>
            </td>
            <td class="list_filter_1 filter-list-data text-left" rowspan="2">Sort Z to A</td>
          </tr>
          <tr
            :class="[direction === 'desc' ? 'selected-row' : '']"
            class="filter-option-list">
            <td class="list_filter_1 filter-list-data col-1" style="color: #1e8bcd">A</td>
          </tr>


<!--          TEMPORARY COMMENTED DO NOT DELETE -->

<!--          <tr class="filter-option-list" @mouseover="highlightData(2, true)" @mouseout="highlightData(2, false)" @click="setDataChecked(true)">-->
<!--            <td class="list_filter_2 filter-list-data" colspan="2">-->
<!--              <div style="position: relative">-->
<!--                <font-awesome-icon-->
<!--                  icon="filter"-->
<!--                  style="font-size: 16px; transform: scaleX(0.8); z-index: 1000"-->
<!--                />-->
<!--                <div style="position: absolute; bottom: -2px; right: 3px; font-size: 14px; color: red">-->
<!--                  &times;-->
<!--                </div>-->
<!--              </div>-->
<!--            </td>-->
<!--            <td class="list_filter_2 filter-list-data text-left">Clear Filter From "Name"</td>-->
<!--          </tr>-->

          <tr class="filter-option-list">
            <td class="filter-list-data"></td>
            <td class="filter-list-data"></td>
            <td class="filter-list-data">
              <div style="padding-right: 5px" class="pl-0 ml-0 mr-0 mt-1">
                <div class="input-search-group w-100" style="border-radius: 4px">
                  <div class="col-11 p-0 m-0">
                    <input type="text" class="filter-search-input form-control form-control-sm w-100 pr-0"
                           :placeholder="'Search'"
                           v-model="searchedValue"
                    >
                  </div>
                  <div class="col-1 p-0 m-0 clear-search" style="font-size: 20px" v-if="searchedValue.trim() !== ''" @click="searchedValue = ''">
                    &times;
                  </div>
                </div>
              </div>
            </td>
          </tr>

          <tr class="filter-option-list">
            <td class="filter-list-data"></td>
            <td class="filter-list-data"></td>
            <td class="filter-list-data">
              <div class="checkbox-group text-left">
                <template v-if="(searchedValue.trim() === '' && data.length === 0) || (searchedValue.trim() !== '' && allItemsNotVisible === true)">
                  <div class="no-matches">
                    No Matches
                  </div>

                </template>
                <template v-else>
                  <div class="form-check form-check-inline pl-1 w-100" v-if="showSelectAll">
                    <input class="form-check-input checkbox-input" type="checkbox" value="" id="filter_select_all" @click="setAllData" :checked="whereIn.length === data.length">
                    <label for="filter_select_all" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer pr-1">(Select All)</label>
                  </div>
                  <div class="form-check form-check-inline pl-1 w-100" v-if="searchedValue.trim() !== '' && allItemsNotVisible === false">
                    <input class="form-check-input checkbox-input" type="checkbox" value="" id="filter_select_all" v-model="addCurrentSelectionToFilter" :checked="addCurrentSelectionToFilter">
                    <label for="filter_select_all" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer pr-1">Add current selection to filter</label>
                  </div>
                  <div class="form-check form-check-inline pl-1 w-100" v-for="(item, index) in data" v-if="item.visible === true">
                    <input class="form-check-input checkbox-input" type="checkbox" value="" :id="'filter_select_' + index" :checked="item.checked" @click="item.checked = !item.checked">
                    <label :for="'filter_select_' + index" class="font-weight-bold col-m-3 col-form-label no-select cursor-pointer pr-1" style="white-space: nowrap">{{ item.text }}</label>
                  </div>
                </template>
              </div>
            </td>
          </tr>
          <tr class="filter-option-list">
            <td class="filter-list-data"></td>
            <td class="filter-list-data"></td>
            <td class="filter-list-data">
              <div class="w-100 mt-1">
                <button type="button" class="btn btn-sm btn-secondary float-right mr-2" @click="cancelRequest">Cancel</button>
                <button type="button" class="btn btn-sm btn-primary text-right float-right mr-1" :disabled="okButtonDisabled" @click="submitRequest">OK</button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</template>


<script>
import Table from "@/components/layouts/Table.vue";
import {FILTER_MENU} from "@/services/ConstantService";
import {GlobalService} from "@/services/GlobalService";
import axios from "axios";
import {AlertService} from "@/services/AlertService";
import Vue from "vue";
import filterLikeExcelMixin from "@/mixins/filterLikeExcelMixin";
import {DataService} from "@/services/DataService";
import Ar from "vue2-datepicker/locale/es/ar";
export const EventBus = new Vue();
export default {
  name : 'FilterLikeExcel',
  mixins : [filterLikeExcelMixin],
  components: {Table},
  props : {
    module : {
      type : String,
      default : ''
    },
    columnName : {
      type : String,
      default: ''
    },
    filterOptionToRight : {
      type : Boolean,
      default : false
    },
    customParamForGettingMenu : {
      type : Array,
      default : null
    }
  },
  watch : {
    searchedValue() {
      let searchedItems = this.data.filter(item => item.text.toLowerCase().match(this.searchedValue.toLowerCase()));

      this.showSelectAll = this.searchedValue.trim() === '';

      this.data.forEach(item => {
        const data = searchedItems.filter(searchItem => searchItem.value === item.value);
        item.visible = data.length > 0;
      });
    }
  },
  methods: {
    cancelRequest() {
      let newData = [];
      this.previousData.forEach(item => {
        const newItem = {
          checked : item.checked,
          text : item.text,
          value : item.value,
          visible : item.visible,
        }
        newData.push(newItem);
      });
      this.data = newData;
      this.showFilterMenu = false;
    },
    submitRequest() {
      if (this.okButtonDisabled === true) {
        return;
      }

      if (this.searchedValue.trim() !== '') {
        const hiddenDataButChecked = this.data.filter(item => {
          return item.visible === false && item.checked === true;
        });

        if (this.addCurrentSelectionToFilter === false) {
          hiddenDataButChecked.forEach(item => {
            item.checked = false;
          });
        }
      }
      this.searchedValue = '';
      this.addCurrentSelectionToFilter = false;
      this.setPreviousData();
      this.showFilterMenu = false;

      this.$emit('setFilterParam', {
        column_name  : this.columnName,
        direction    : this.direction,
        where_in     : this.whereIn
      }, this.data.length)

    },
    setSorting(direction) {
      this.direction = direction;
      this.$emit('setFilterParam', {
        column_name  : this.columnName,
        direction    : this.direction,
        where_in     : this.whereIn
      }, this.data.length);

      EventBus.$emit('resetDirection', this.filterExcelUniqueId);
      this.hideMenu();
    },
    setDataChecked(setToChecked) {
      this.data.forEach(item => {
        item.checked = setToChecked;
      });
    },
    setAllData() {
      let setToChecked = true;
      if (this.whereIn.length === this.data.length) {
        setToChecked = false;
      }
      this.setDataChecked(setToChecked);
    },
    async requestData() {
      let params = {
        module : this.module,
        column_name : this.columnName
      };

      if (this.customParamForGettingMenu !== null) {
        this.customParamForGettingMenu.forEach(item => {
          params[item.key] = item.value;
        });
      }

      const response = await GlobalService.ajax('get', FILTER_MENU, {params});

      // let data = [
      //   {
      //     checked : true,
      //     text : 'ADRIATIC HIGHWAY',
      //     value : 'ADRIATIC HIGHWAY',
      //     visible : true,
      //   },
      //   {
      //     checked : true,
      //     text : 'AEGEAN HIGHWAY',
      //     value : 'AEGEAN HIGHWAY',
      //     visible : true,
      //   },
      //   {
      //     checked : true,
      //     text : 'ALBION BAY',
      //     value : 'ALBION BAY',
      //     visible : true,
      //   },
      //   {
      //     checked : true,
      //     text : 'AMERICAN HIGHWAY',
      //     value : 'AMERICAN HIGHWAY',
      //     visible : true,
      //   },
      //   {
      //     checked : true,
      //     text : 'APOLLON HIGHWAY',
      //     value : 'APOLLON HIGHWAY',
      //     visible : true,
      //   },
      //   {
      //     checked : true,
      //     text : 'ATHENS HIGHWAY',
      //     value : 'ATHENS HIGHWAY',
      //     visible : true,
      //   },
      //   {
      //     checked : true,
      //     text : 'BALTIMORE HIGHWAY',
      //     value : 'BALTIMORE HIGHWAY',
      //     visible : true,
      //   },
      //   {
      //     checked : true,
      //     text : 'BANGKOK HIGHWAYssssssssssssssss',
      //     value : 'BANGKOK HIGHWAYssssssssssssssss',
      //     visible : true,
      //   },
      // ];
      // if (this.columnName === 'management_company_id') {
      //   data = [
      //     {
      //       checked : true,
      //       text : '"K" Line RoRo Bulk Ship Management Co., Ltd.',
      //       value : '"K" Line RoRo Bulk Ship Management Co., Ltd.',
      //       visible : true,
      //     },
      //     {
      //       checked : true,
      //       text : 'STARGATE SHIPMANAGEMENT GmbH',
      //       value : 'STARGATE SHIPMANAGEMENT GmbH',
      //       visible : true,
      //     },
      //     {
      //       checked : true,
      //       text : 'Blue Marine Management Corporation',
      //       value : 'Blue Marine Management Corporation',
      //       visible : true,
      //     },
      //     {
      //       checked : true,
      //       text : 'Taiyo Nippon Kisen Co., Ltd.',
      //       value : 'Taiyo Nippon Kisen Co., Ltd.',
      //       visible : true,
      //     }
      //   ];
      // }
      //
      // const response = {
      //   status : 200,
      //   data : {
      //     data : data
      //   }
      // }

      if (response.hasOwnProperty('status') && response.status === 200) {
        return {
          result : true,
          data   : response?.data?.data
        };
      }
      return {
        result : false,
        message : response.message
      };
    },
    async showMenu() {
      this.showFilterMenu = !this.showFilterMenu;

      if (this.showFilterMenu === true) {
        EventBus.$emit('hideMenu', this.filterExcelUniqueId);

        if (this.doneToRequest === true) {
          return;
        }
        const dataRequest = await this.requestData();
        if (dataRequest.result === false) {
          AlertService.errorAlert(dataRequest.message, 'DATA REQUEST FAILED');
        }

        this.data = dataRequest.data;
        this.setPreviousData();
        this.showSelectAll = this.data.length > 0;
        this.doneToRequest = true;
      }
    },
    setPreviousData() {
      this.previousData = [];
      this.data.forEach(item => {
        const newItem = {
          checked : item.checked,
          text : item.text,
          value : item.value,
          visible : item.visible,
        }
        this.previousData.push(newItem);
      });
    },
    hideMenu(filterExcelUniqueId) {
      if (this.filterExcelUniqueId !== filterExcelUniqueId) {
        this.showFilterMenu = false;
        this.searchedValue = '';
      }
    },
    resetDirection(filterExcelUniqueId) {
      if (this.filterExcelUniqueId !== filterExcelUniqueId) {
        this.direction = '';
      }
    },
    highlightData(rowIndex, hover) {
      const allList = $('.list_filter_' + rowIndex);
      for (let counter = 0; counter < allList.length; counter++) {
        if (hover === true) {
          $(allList[counter]).addClass('list-hover')
        } else {
          $(allList[counter]).removeClass('list-hover')
        }
      }
    },
  },
  created() {
    EventBus.$on('hideMenu', this.hideMenu);
    EventBus.$on('resetDirection', this.resetDirection);
  },
  beforeDestroy() {
    EventBus.$off('resetDirection', this.resetDirection);
  },
  computed : {
    okButtonDisabled() {
      if (this.searchedValue.trim() !== '') {
        return this.allItemsNotVisible === true;
      }
      return this.haveItemsChecked === false || this.hasChanges === false;
    },
    haveItemsChecked() {
      return this.data.filter(item => item.checked === true).length > 0;
    },
    allItemsNotVisible() {
      return this.data.filter(item => item.visible === true).length === 0;
    },
    whereIn() {
      return this.data.filter(item => item.checked === true).map(item => item.value);
    },
    hasChanges() {
      const allPreviousData = this.previousData;
      const allCurrent = this.data;

      allPreviousData.forEach((item, index) => {
        item.changed = DataService.checkIfChanged(item, allCurrent[index]);
      });

      return allPreviousData.filter(item => item.changed === true).length > 0;
    }
  },
  mounted() {
    let _this = this;
    $(document).ready(function() {
      $(document).click(function(event) {
        let target = $(event.target);
        const targetId = target.attr('id');
        if (targetId === 'menuShowerOrHider') {
          return;
        }
        if(!target.closest('#filter-option-menu').length && $('#filter-option-menu').is(":visible")) {
          _this.showFilterMenu = false;
        }
      });
    });
  }
}
</script>

<style scoped>

.clear-search:hover {
  background-color: red;
  color: white;
}

.arrow-sort-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 17px;
  height: 17px;
  background-color: white;
  border: 1px solid black;
}

.relative-arrow-sort-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.center-inside-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sort-down-icon {
  font-size: 16px;
  top: 5px;
}

.sort-down-icon-with-direction {
  font-size: 12px;
}

.arrow-sort-container:hover, .active-sort-container {
  background-color: red;
}

.checkbox-group {
  position: relative;
  border: 1px solid gray;
  min-height: 200px;
  margin-right: 5px;
  margin-top: 5px;
  max-height: 200px;
  max-width: 217px;
  min-width: 217px;
  overflow-y: auto;
  overflow-x: auto;
}

.input-search-group {
  display: inline-flex;
  border: 1px solid black;
}

td.filter-list-data {
  padding: 0;
  font-size: 14px;
  font-weight: normal;
}

.filter-option-container {
  position: absolute;
  width: auto;
  min-width: 250px;
  background-color: white;
  border: 1px solid black;
  right: 0;
  top: 0;
  border-radius: 5px;
  padding-bottom: 5px;
  box-shadow : rgba(0, 0, 0, 0.5) 0px 0px 5px 5px;
}

.filter-search-input, .filter-search-input:focus {
  border: none !important;
  box-shadow : none;
}

.no-matches {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sort-arrow-icon {
  font-size: 12px;
  transform: scaleX(0.5);
  margin-left: -2px
}

.sort-arrow-icon-small {
  font-size: 8px;
  transform: scaleX(0.8);
}

.filter-icon {
  font-size: 10px;
  margin-left: -3px
}
</style>
