<template>
  <!-- Modal -->
  <div class="modal fade" :id="popperModalId" tabindex="-1" role="dialog" aria-labelledby="popperModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="popperModalTitle">
            <slot name="popperHeader"></slot>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="popperBody"></slot>
        </div>
        <div class="modal-footer text-left">
          <slot name="popperFooter"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PopperModal",
  props: ['popperId'],
  data(){
    return {
      popperModalId: this.popperId
    }
  }
}
</script>
<style scoped>

</style>
