<template>
  <div class="input-group">
    <input type="text" class="form-control form-control-sm" v-model="searchTerm" placeholder="Type to search...">
    <div class="dropdown-menu p-0 m-0 active-custom-element" :class="{ show: showDropdown }" v-if="items.length > 0">
      <a v-for="item in items" class="dropdown-item cursor-pointer item-list" @click="selectItem(item)">{{ item.name }}</a>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
export default {
  name: 'SearchDropdown',
  props: {
    items : {
      type: Array,
      default : []
    }
  },
  data() {
    return {
      searchTerm: '',
      showDropdown: false,
      selectedItem: null,
    };
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item.name;
      this.showDropdown = false; // Hide dropdown after selection
      this.$emit('setSelectedItem', item);
      this.searchTerm = '';
    },
    setSearchTerm() {
      this.$emit('setSearchTerm', this.searchTerm);
    }
  },
  created() {
    this.doSearchTerm = _.debounce(this.setSearchTerm, 750);
  },
  watch: {
    searchTerm() {
      if (this.searchTerm === '') {
        this.showDropdown = false;
      } else {
        this.showDropdown = true;
        this.doSearchTerm();
      }

    }
  }
};
</script>

<style scoped>
/* Add any custom styles here */
.dropdown-menu {
  display: none; /* Hide by default */
  position: absolute;
  z-index: 1000;
  width: 100%;
  //min-height: 100px; /* Set a minimum height */
  max-height: 200px; /* Set a maximum height if needed */
  overflow-y: auto; /* Enable vertical scrollbar */
}
.show {
  display: block; /* Show when the dropdown is active */
}

.cursor-pointer{
  cursor: pointer;
}

.item-list:hover{
  color: #fff;
  background-color: #0069d9; /* Original primary color */
  border-color: #0062cc;
}
</style>
