<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-12">
        <button v-if="editMode === true" type="button" :disabled="disabled" class="font-weight-bold btn btn-xs border-secondary w-100 text-left"  data-toggle="modal" :data-target="'#'+id" style="min-height: 30px;">
          <template v-if="Array.isArray(selectedTexts)">
            <span class="badge badge-secondary badge-pill mt-1 mr-1 p-2 mb-1 d-inline-block" v-for="text in selectedTexts">{{text}}</span>
          </template>
          <template v-else>{{selectedTexts}}</template>
        </button>
        <div v-else type="button" class="font-weight-bold btn btn-xs border-secondary w-100 text-left" style="min-height: 30px;">
          <template v-if="Array.isArray(selectedTexts)">
            <span class="badge badge-secondary badge-pill mt-1 mr-1 p-2 mb-1 d-inline-block" v-for="text in selectedTexts">{{text}}</span>
          </template>
        </div>
      </div>
    </div>
    <div class="modal fade" data-backdrop="static" data-keyboard="false" :id="id" tabindex="-2" :aria-labelledby="id + 'Label'" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <form class="modal-content" @submit.prevent="">
          <div class="modal-header">
            <div class="input-group w-100">
              <h5 class="modal-title">SELECT: <strong class="font-weight-bolder">{{entity.toUpperCase()}}</strong></h5>
              <input type="text" class="form-control form-control-sm ml-5" placeholder="Search" id="searchInput" v-model="searchValue" @submit="event.preventDefault()">
              <div class="input-group-append"  v-if="searchValue.trim() !== ''">
                <button class="btn btn-link" type="button" id="clearButton" aria-label="Clear" @click="searchValue = ''">&times;</button>
              </div>
            </div>
          </div>
          <div class="modal-body" style="min-height: 200px; max-height: 450px; overflow-y: auto;">
              <div class="row align-items-center" v-for="item in searchedItems">
                <div class="col-lg-12">
                  <input v-model="itemSelected" type="checkbox" class="align-middle" :value="item[defValue]" name="item" :checked="itemSelected.includes(item[defValue])">
                  <span class="mx-2 align-middle cursor-pointer no-select" style="font-size: 18px;" @click="selectItem(item[defValue])">
                  {{item[name] ? item[name].toUpperCase() : ''}}
                </span>
                </div>
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-secondary" @click="cancelSelected">Cancel</button>
            <button type="button" class="btn btn-sm btn-primary" @click="doneSelected">Done</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "MultiSelectWithSearch",
  data(){
    return{
      itemSelected:this.selected,
      searchValue : '',
      searchedItems : this.items,
    }
  },
  props:{
    items:{
      type:Object|Array,
      default:null,
    },
    id:{
      type:String,
      default: 'multiSelect'
    },
    name:{
      type:String,
      default:'name',
    },
    defValue:{
      type:String,
      default:'id',
    },
    entity:{
      type:String,
      default:'ENTITIES'
    },
    selected:{
      type:Object|Array,
      default:null,
    },
    disabled:{
      type: Boolean,
      default:false
    },
    editMode : {
      type : Boolean,
      default : true
    }
  },
  computed:{
    selectedTexts(){
      if(this.itemSelected.length){
        return this.items.filter(item => this.itemSelected.includes(item.id)).map(item => {
          return item.name.toUpperCase();
        })
      }
      return 'SELECT ' + this.entity.toUpperCase();
    }
  },
  methods:{
    doneSelected(){
      $('#'+this.id).modal('hide');
      this.$emit('doneSelected',{selected:this.itemSelected});
    },
    cancelSelected() {
      $('#'+this.id).modal('hide');
      this.itemSelected = this.selected;
      // this.$emit('doneSelected',{selected:this.itemSelected});
    },
    selectItem(itemDefValue) {
      if (this.itemSelected.includes(itemDefValue) === true) {
        this.itemSelected = this.itemSelected.filter(item => item !== itemDefValue);
      } else {
        this.itemSelected.push(itemDefValue);
      }
    }
  },
  mounted() {
    $('#' + this.id).find('.modal-content').css({
      boxShadow : '0 0 10px 10px rgba(0, 0, 0, 0.5)',
      minHeight : '500px',
      maxHeight : '500px',
    });
  },
  watch:{
    selected(val){
      if (this.itemSelected.length === 0) {
        val.forEach(data => {
          this.itemSelected.push(data)
        });
      }
    },
    items() {
      this.searchedItems = this.items;
    },
    searchValue() {
      let searchedItems = this.items.filter(item => item.name.toLowerCase().match(this.searchValue.toLowerCase()));
      if (this.searchValue.trim() === '') {
        this.searchedItems = this.items;
      } else {
        this.searchedItems = searchedItems;
      }
    }
  }
}
</script>

<style scoped>
    input[type='checkbox']{
      width: 20px;
      height: 20px;
    }
    .modal-dialog{
      overflow-y: initial !important
    }
    .modal-body{
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }


    .input-group .btn-link {
      padding: 0;
      border: none;
      font-size: 1.25rem;
      color: #000;
      text-decoration: none;
      position: absolute;
      right: 5px;
      z-index: 1000;
    }

    .input-group .btn-link:hover {
      color: #000;
      text-decoration: none;
      background-color: transparent;
    }

    .input-group .btn-link:focus {
      box-shadow: none;
    }
</style>
